<template>
	<el-dialog :close-on-click-modal="false" :modal-append-to-body="false" :append-to-body="true" title="转发给合作行长"
		:visible.sync="drawer_" top="5vh" open="opens">

		<transferForm @success="success" @closeVisible="$emit('closeVisible')" :requirementId="requirementId"
			:recommendReason="recommendReason" :checkedTitleField="checkedTitleField" :inquiryAttachment="list"
			:recommendedzReason="recommendedzReason" :clientRequirement="clientRequirement" :noticeMatter="noticeMatter"
			:askQuestions="askQuestions" :remark="remark" :consultKeyPoint="consultKeyPoint" labelPosition="left"
			ref="buildProject" />
		<div slot="footer" v-if="!successDialogVisible" class="dialog-footer">
			<el-button type="primary" @click="postCall">确 定</el-button>
			<el-button
				@click="successDialogVisible = false; $refs.buildProject.mysuccessDialogVisible = false; $emit('closeVisible')">取
				消</el-button>

		</div>
		<div slot="footer" v-else class="dialog-footer">
			<el-button type="primary"
				@click="successDialogVisible = false; $refs.buildProject.mysuccessDialogVisible = false; $emit('closeVisible')">关
				闭</el-button>
		</div>
	</el-dialog>
</template>

<script>
import transferForm from './transferForm.vue';
export default {
	name: 'transfer',
	props: {
		transferDialogVisible: {
			type: Boolean,
			required: true,
		},
		requirementId: {},
		recommendReason: {},
		checkedTitleField: {},
		inquiryAttachment: {},
		recommendedzReason: {},
		clientRequirement: {},
		noticeMatter: {},
		askQuestions: {},
		remark: {},
		consultKeyPoint: {},
	},
	data() {
		return {
			list: [],
			successDialogVisible: false, obj: '',
		};
	},
	methods: {
		postCall() {

			this.$refs.buildProject.getrequirementMappingPresident();
		},
		opens() {
			if (this.inquiryAttachment) {
				this.list = JSON.parse(this.inquiryAttachment).map((item) => ({
					name: item.fileName,
					url: item.filePath,
				}));
			}
		},
		success() {
			this.successDialogVisible = true
		}
	},
	computed: {
		drawer_: {
			get() {
				return this.transferDialogVisible;
			},
			set(v) {
				this.$emit('update:transferDialogVisible', v);
			},
		},
	},
	created() {
		if (this.inquiryAttachment) {
			this.list = JSON.parse(this.inquiryAttachment).map((item) => ({
				name: item.fileName,
				url: item.filePath,
			}));
		}
	},
	components: {
		transferForm,
	},
};
</script>
<style lang="scss" scoped>
.dialog-footer {
	display: flex;
	justify-content: center;
}

::v-deep .el-dialog__body {
	padding: 10px 30px !important;
}
</style>
